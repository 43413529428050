import { html } from "lit-html";
import TopBar from "./TopBar";
import { State, Invite } from "../state";
import Spinner from "./Spinner";
import requestInvite from "../actions/requestInvite";

const MONTHS = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(" ");
function inviteDate(invite: Invite) {
  if (!invite.created) {
    return "";
  }
  const d = invite.created.toDate();
  return html`${MONTHS[d.getMonth()]}&nbsp;${d.getDate()},&nbsp;${d.getFullYear()}`;
}

function InviteStatus(state: State) {
  if (!state.inviteLoaded) {
    return Spinner();
  } else if (!state.invite) {
    return InviteForm();
  } else {
    return html`
      <p><b>You already requested an invite on ${inviteDate(state.invite)}. Stay tuned!</b></p>
    `
  }
}

function InviteForm() {
  return html`
    <form @submit=${requestInvite}>
      <textarea name="message" placeholder="How do you want to use Furl?"></textarea>
      <button class="btn" type="submit">Request Invite</button>
    </form>
  `
}

export default function Univited(state) {
  return html`
    ${TopBar(state)}
    <section id="invite" class="content">
      <div class="card">
        <p><b>Furl</b> is invite-only while it's still under construction.</p>
        ${InviteStatus(state)}
      </div>
    </section>
  `;
}