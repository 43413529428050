import { subscribe, setState, State, Invite } from "../state";
import { db, snapState } from '../firebase/db';
import page from "page";

let getSub: () => void;

subscribe((state: State) => {
  if (state.user && !getSub) {
    getSub = db.collection('invites').doc(state.user.uid).onSnapshot(snap => {
      setState({inviteLoaded: true, invite: snap.exists ? snap.data() : null});
    });
  } else if (!state.user && getSub) {
    getSub();
  }
});
