import { subscribe, setState } from "../state";
import { db, snapState } from '../firebase/db';

let subApp: string;
let listSub: () => void;

subscribe(state => {
  if (state.appid && subApp && subApp !== state.appid) {
    setState({keysLoaded: false, keys: []});
  }

  if (state.appid && !listSub) {
    subApp = state.appid;
    listSub = db.collection('keys').where('app','==',subApp).onSnapshot(snap => {
      setState({keysLoaded: true, keys: snap.docs.map(snapState)});
    });
  }
})
