import { subscribe, setState, State, StateApp } from "../state";
import { db, snapState } from '../firebase/db';
import page from "page";

let listSub: () => void;
let getId: string;
let getSub: () => void;

subscribe((state: State) => {
  if (state.user && !listSub) {
    listSub = db.collection('apps').where('owners', 'array-contains', state.user.uid).orderBy('name').onSnapshot(snap => {
      setState({appsLoaded: true, apps: (snap.docs.map(snapState) as StateApp[])});
    });
  } else if (!state.user && listSub) {
    listSub();
  }

  if (state.appid && state.appid != getId && getSub) {
    getSub();
    getId = null;
    getSub = null;
    setState({appLoaded: false, app: null});
  }

  if (state.appid && !getSub) {
    getId = state.appid;
    getSub = db.collection('apps').doc(state.appid).onSnapshot(snap => {
      setState({appLoaded: true, app: snapState(snap)});
    });
  }
});
