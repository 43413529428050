import { subscribe, setState } from "../state";
import { db, snapState } from '../firebase/db';

let subApp: string;
let listSub: () => void;

subscribe(state => {
  if (state.appid && subApp && subApp !== state.appid) {
    setState({usageLoaded: false, usage: []});
  }

  if (state.appid && !listSub) {
    subApp = state.appid;
    const now = new Date();
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    console.log(now);
    listSub = db.collection('usage').where('app','==',subApp).where('hour', '==', now).onSnapshot(snap => {
      setState({usageLoaded: true, usage: snap.docs.map(snapState)});
    });
  }
})
