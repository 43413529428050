import {getState, subscribe, setState} from "./state";
import {render as litRender} from "lit-html";
import App from "./components/App";
import SignIn from "./components/SignIn";
import "./routes";
import Spinner from "./components/Spinner";
import Univited from "./components/Uninvited";

async function render(state) {
  let page;
  if (state.user && state.claims?.invited) {
    page = await App(state);
  } else if (!state.claimsLoaded) {
    page = Spinner();
  } else if (state.user) {
    page = Univited(state);
  } else {
    page = SignIn(state);
  }

  litRender(page, document.getElementById('app'));
}

async function boot() {
  render(getState());

  const {auth} = await import("./firebase/auth");
  await Promise.all([
    import("./firebase/performance"),
    import("./firebase/analytics"),
  ]);
  auth.onAuthStateChanged(async user => {
    setState({user, userLoaded: true, claimsLoaded: false, claims: null});
    if (user) {
      const token = await user.getIdTokenResult(); 
      setState({claims: {invited:token.claims.invited, role: token.claims.role}, claimsLoaded: true});      
    } else {
      setState({claims: null, claimsLoaded: true});
    }
  });

  subscribe((state) => {
    render(state);
  });

  await Promise.all([
    import("./listeners/apps"),
    import("./listeners/keys"),
    import("./listeners/usage"),
    import("./listeners/invite"),
  ]);
}

boot();
