import { html } from "lit-html";
import { State, StateApp } from '../state';
import TopBar from './TopBar';
import * as pages from "./pages";

const loadedPages = {};

async function renderPage(state) {
  console.log(loadedPages, pages);
  if (loadedPages[state.page]) {
    console.log("Rendering page", state.page);
    return loadedPages[state.page](state);
  }

  loadedPages[state.page] = await pages[state.page]();
  return renderPage(state);
}

export default async function App(state: State) {
  return html`
    ${TopBar(state)}
    <section class="content" page=${state.page}>
      ${state.page ? await renderPage(state) : "loading..."}
    </section>
  `;
}
