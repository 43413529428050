import firebase from "@firebase/app";

firebase.initializeApp({
  apiKey: "AIzaSyDMG6GIHSTxUj5Bk-KdSD60L7B-HhH2I2g",
  authDomain: "furl.dev",
  databaseURL: "https://furl-prod.firebaseio.com",
  projectId: "furl-prod",
  storageBucket: "furl-prod.appspot.com",
  messagingSenderId: "505252426828",
  appId: "1:505252426828:web:5b9dd9b85c2041dca270e9",
  measurementId: "G-3BJ2Z7443Q"
});

export default firebase;
