import createStore from 'unistore';
import {Timestamp} from '@firebase/firestore-types';

export interface StateUser {
  displayName: string;
  email: string;
  uid: string;
  photoURL: string;
}

export interface StateApp {
  id: string;
  name: string;
  owners: string[];
}

export interface StateKey {
  id: string;
  app: string;
  origins: string[];
}

export interface UserClaims {
  role?: "admin" | null;
  invited?: boolean;
}

export interface Invite {
  created: Timestamp;
  message: string;
}

export interface State {
  page: string | null;
  user: StateUser | null;
  userLoaded: boolean;
  apps: StateApp[];
  appsLoaded: boolean;
  appid: string | null;
  app: StateApp | null;
  appLoaded: boolean;
  keys: StateKey[];
  keysLoaded: boolean;
  claims: UserClaims | null;
  claimsLoaded: boolean;
  invite: Invite | null;
  inviteLoaded: boolean;
}

const store = createStore<State>({
  page: null,
  user: null,
  userLoaded: false,
  apps: [],
  appsLoaded: false,
  appid: null,
  app: null,
  appLoaded: false,
  keys: [],
  keysLoaded: false,
  claims: null,
  claimsLoaded: false,
});

const [getState, setState, subscribe] = [store.getState, store.setState, store.subscribe];
subscribe(state => window["state"] = state);

export function loggedSetState(s) {
  console.log('setState called with', s);
  setState(s);
}

export {getState, loggedSetState as setState, subscribe}
