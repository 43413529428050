import { html } from "lit-html";
import { State } from '../state';
import signOut from "../actions/signOut";
import signIn from "../actions/signIn";

function signedIn(state: State) {
  return html`
    <nav>
      ${state.claims.invited ? html`<a href="/apps">Apps</a>` : ''}
      <a href="/docs" target="_blank">Docs</a>
    </nav>
    <aside id="user">
      <img src="${state.user.photoURL}" alt="Profile Photo">
      <button @click=${signOut}>Sign Out</button>
    </aside>
  `;
}

function signedOut(state: State) {
  return html`
    <nav></nav>
    <aside>
      Sign in with
      <button @click=${signIn} provider="github">GitHub</button>
      <button @click=${signIn} provider="google">Google</button>
    </aside>
  `;
}

export default function TopBar(state: State) {
  return html`
    <header id="top-bar">
      <a id="logo" href="/apps">Furl</a>
      ${state.user ? signedIn(state) : signedOut(state)}
    </header>
  `;
}
