import {db, firestore} from "../firebase/db";
import { getState } from "../state";

export default async function requestInvite(e: Event) {
  e.preventDefault();
  await db.collection('invites').doc(getState().user.uid).set({
    email: getState().user.email,
    name: getState().user.displayName,
    created: firestore.FieldValue.serverTimestamp(),
    message: ((e.target as HTMLElement).querySelector('[name=message]') as HTMLFormElement).value,
  });
}