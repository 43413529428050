import { html } from "lit-html";
import signIn from "../actions/signIn";
import TopBar from './TopBar';
import Spinner from './Spinner';

export default function SignIn(state) {
  return html`
    ${TopBar(state)}
    ${state.userLoaded ? html`
    <section id="sign-in" class="content">
      <div class="card">
        <p>Choose an authentication provider below to sign in to <b>Furl</b>.</p>
        <button class="btn" provider="github" @click=${signIn}>Sign in with GitHub</button>
        <button class="btn" provider="google" @click=${signIn}>Sign in with Google</button>
      </div>
    </section>
  ` : html`<div class="page-center">${Spinner('full-page')}</div>`}
  `;
}
