import firebase from "./firebase";
import "@firebase/firestore";
import { DocumentSnapshot } from "@firebase/firestore-types";

export const db = firebase.firestore();
export const firestore = firebase.firestore;


export function snapState(doc: DocumentSnapshot) {
  return Object.assign({id: doc.id}, doc.data());
}
