import { StateApp } from "../state";
import { html } from 'lit-html';

import deleteApp from "../actions/deleteApp";

export default function AppCard(app: StateApp) {
  return html`
    <div class="app-card card">
      <h3><a href="/apps/${app.id}">${app.name}</a></h3>
      <div class="app-id"><b>App ID:</b> ${app.id}</p></div>
      <footer>
        <a class="btn manage" href="/apps/${app.id}">Manage</a>
        <a class="delete" appid=${app.id} @click=${deleteApp}>Delete</a>
      </footer>
    </div>
  `
}
