import { State } from '../../state';
import Spinner from '../Spinner';
import { html } from 'lit-html';

export default function AppsGet(state: State) {
  if (!state.app) {
    return html`
      <div class="page-center">${Spinner('full-page')}</div>
    `;
  }

  return html`
    <h1>${state.app.name}</h1>

    <h2>API Keys</h2>
    ${state.keys.map(key => html`<div class="card key-card">
      <h3>${key.name}</h3>
      <p><b>API Key:</b> ${key.id}</p>
      <p><b>Allowed Origins:</b> ${(key.origins || []).map(origin => html`<span>${origin}</span>`)}</p>
    </div>
  `;
}
