import { State } from "../../state";
import { html } from 'lit-html';
import AppCard from '../AppCard';
import createApp from "../../actions/createApp";

function submitCreateApp(e: Event) {
  e.preventDefault();
  const name = ((e.target as HTMLElement).querySelector('[name=app_name]') as HTMLFormElement).value;
  createApp(name);
}

export default function AppsList(state: State) {
  return html`
    <h1>Apps</h1>
    <section id="app-list">
      ${state.apps ? state.apps.map(AppCard) : "Loading..."}
      <div class="card app-card">
        <h3>Create an App</h3>
        <form id="create-app" @submit=${submitCreateApp}>
          <input type="text" name="app_name" placeholder="App Name...">
          <button type="submit">Create</button>
        </form>
      </div>
    </section>
  `
}
