import firebase from "./firebase";
import "@firebase/auth";

const auth = firebase.auth();
const githubProvider = new firebase.auth.GithubAuthProvider();
githubProvider.addScope('user:email');

const googleProvider = new firebase.auth.GoogleAuthProvider();

const providers = {github: githubProvider, google: googleProvider};
export { auth, providers }
