import { getState } from "../state";
import { db, firestore } from '../firebase/db';
import logEvent from "../firebase/analytics";
import { EventNameString } from "@firebase/analytics-types";

export default async function createApp(name: string) {
  getState();
  logEvent("create_app" as EventNameString);
  const appRef = await db.collection('apps').add({name, owners: [getState().user.uid], create_time: firestore.FieldValue.serverTimestamp(), update_time: firestore.FieldValue.serverTimestamp()});
  const keyData = {app: appRef.id, name: "Default API Key", origins: ["*"]};
  console.log(keyData);
  return db.collection('keys').add(keyData);
}
