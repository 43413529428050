import page from "page";
import { setState } from "./state";

page('/apps', () => {
  console.log('page fired');
  setState({page: 'AppsList'})
});
page('/', '/apps');
page('/apps/:id', (ctx) => {
  setState({page: 'AppsGet', appid: ctx.params.id});
});
page('*', () => setState({page: 'NotFound'}));
page.start();
